import $ from 'jquery';
import moment from 'moment';


$(function() {
    // Header menu slide from the top
    $('[data-toggle="slide-collapse"]').on('click', function() {
        let navMenuCont = $($(this).data('target'));
        
        $(this).toggleClass('navbar-toggler--rotate');
        navMenuCont.toggleClass('navbar-collapse--slide-down')
        $('body').toggleClass('overflow-hidden');
    })
    
    
    // Header menu real-time hour & date
    setInterval(function(){
        $('#navbarMainMenu .navbar-top__orario').text(moment().format('H:mm:ss'));
    }, 1000);

    $('#navbarMainMenu .navbar-top__data').text(moment().format('DD.MM.YYYY'));


    // Slider news correlate
    const swiperNewsRelated = new Swiper('.swiper-container.swiper-news-related', {
        slidesPerView: "auto",
        spaceBetween: 24,
    });

    // Slider Archivio edizioni
    const swiperArchive = new Swiper('.swiper-container.swiper-archive', {
        slidesPerView: "auto",
        spaceBetween: 24,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        breakpoints: {
            // when window width is >= 768px
            768: {
                spaceBetween: 64
            }
        }
    });

    // Slider Archivio edizioni
    const swiperArchiveSingle = new Swiper('.swiper-container.swiper-archive-single', {

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
});